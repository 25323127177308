(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var splide_1 = require("@splidejs/splide");
var banner = document.querySelector(".splide.news");
var splide = new splide_1.Splide(banner, {
  type: 'loop',
  rewind: true,
  autoplay: true,
  interval: 2000,
  perPage: 3,
  perMove: 1,
  gap: 16,
  breakpoints: {
    768: {
      perPage: 2.3,
      perMove: 1,
      gap: 0
    },
    576: {
      perPage: 1.5,
      perMove: 1,
      gap: 0
    }
  }
});
splide.mount();

},{"@splidejs/splide":"@splidejs/splide"}]},{},[1]);
